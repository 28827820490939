import PDFCourseContent from './PDFCourseContent';
import VideoContent from './VideoContent';

// Referencje do oryginalnych klas przed opakowaniem przez withTranslation
const PDFContentClass = PDFCourseContent.WrappedComponent || PDFCourseContent;
const VideoContentClass = VideoContent.WrappedComponent || VideoContent;

// Sprawdzamy czy klasa potrafi obsłużyć dany format
const canHandleContent = (HandlerClass, url) => {
    try {
        return HandlerClass.canHandle(url);
    } catch (e) {
        console.error(`Error checking handler for ${url}:`, e);
        return false;
    }
};

// Lista dostępnych handlerów
const CONTENT_HANDLERS = [
    {
        component: VideoContent,
        check: (url) => canHandleContent(VideoContentClass, url)
    },
    {
        component: PDFCourseContent,
        check: (url) => canHandleContent(PDFContentClass, url)
    }
];

/**
 * Returns appropriate content handler for given URL
 * @param {string} url - Content URL
 * @returns {React.Component} Content handler component
 * @throws {Error} When no handler can handle the content
 */
export const getContentHandler = (url) => {
    const handler = CONTENT_HANDLERS.find(h => h.check(url));
    if (!handler) {
        throw new Error(`No content handler found for URL: ${url}`);
    }
    return handler.component;
};

export { PDFCourseContent, VideoContent };
