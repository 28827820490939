import React from 'react';
import ReactPlayer from 'react-player';
import { Button, Container, Row, Col, Badge } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { BsPlayFill, BsPauseFill, BsSkipBackward, BsSkipForward } from 'react-icons/bs';
import BaseCourseContent from './BaseCourseContent';

const EXAM_BUTTON_SHOW_THRESHOLD = 0.85; // Show exam button when 85% of video is played
const SHOW_SEEK_CONTROLS = true; // Set to false to hide seek controls

/**
 * Component for handling video content from various providers (YouTube, Vimeo, Facebook)
 */
class VideoContent extends BaseCourseContent {
    static canHandle(url) {
        return ReactPlayer.canPlay(url);
    }

    state = {
        isVideoFinished: false,
        isPlaying: true,
        playedSeconds: 0,
        totalSeconds: 0,
        showExamButton: false,
        played: 0,
        isSeekingLocked: false
    };

    playerRef = React.createRef();

    handleProgress = ({ playedSeconds, played }) => {
        const { totalSeconds } = this.state;
        const shouldShowExamButton = totalSeconds > 0 && playedSeconds >= totalSeconds * EXAM_BUTTON_SHOW_THRESHOLD;
        
        this.setState({ 
            playedSeconds,
            played,
            showExamButton: shouldShowExamButton,
            // Reset isVideoFinished if we're not near the end
            isVideoFinished: played >= 0.99 ? this.state.isVideoFinished : false
        });
        
        if (played >= 0.99) {
            if (!this.state.isVideoFinished) {
                this.setState({ 
                    isVideoFinished: true,
                    isPlaying: false // Stop playback when finished
                });
            }
        }
    };

    handleDuration = (duration) => {
        this.setState({ totalSeconds: duration });
    };

    handlePlayPause = () => {
        this.setState(state => ({ isPlaying: !state.isPlaying }));
    };

    handleSeek = async (seconds, direction = 'forward') => {
        const { isSeekingLocked, playedSeconds, totalSeconds } = this.state;

        if (isSeekingLocked) return;

        this.setState({ isSeekingLocked: true });

        if (this.playerRef.current) {
            const newTime = direction === 'forward' 
                ? Math.min(playedSeconds + seconds, totalSeconds)
                : Math.max(playedSeconds - seconds, 0);

            try {
                await this.playerRef.current.seekTo(newTime, 'seconds');
            } catch (error) {
                console.error('Seeking failed:', error);
            }

            // Add a small delay before unlocking to prevent rapid clicking
            setTimeout(() => {
                this.setState({ isSeekingLocked: false });
            }, 300);
        }
    };

    handleSkipBackward = (seconds = 10) => {
        this.handleSeek(seconds, 'backward');
    };

    handleSkipForward = (seconds = 10) => {
        this.handleSeek(seconds, 'forward');
    };

    getPlayerConfig = (url) => {
        const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
        const isVimeo = url.includes('vimeo.com');
        const isFacebook = url.includes('facebook.com');

        const config = {};

        if (isYouTube) {
            config.youtube = {
                playerVars: { 
                    modestbranding: 1, // Hide YouTube logo
                    controls: 0, // Disable native controls
                    disablekb: 1, // Disable keyboard controls
                    rel: 0, // Disable related videos
                    showinfo: 0, // Hide video information
                    iv_load_policy: 3, // Disable annotations
                    autoplay: 1, // Enable autoplay
                    fs: 0, // Disable fullscreen button
                    playsinline: 1, // Force playback within iframe
                    origin: window.location.origin, // Security: prevent redirects
                    playlist: url.split('v=')[1], // Required to stop on last frame
                    endSeconds: 0.5 // Stop slightly before the end to prevent suggestions
                }
            };
        }

        if (isVimeo) {
            config.vimeo = {
                playerOptions: {
                    controls: false, // Disable native controls
                    autoplay: true, // Enable autoplay
                    dnt: true, // Disable tracking
                    playsinline: true, // Force playback within iframe
                    portrait: false, // Hide author avatar
                    title: '', // Hide video title (empty string instead of false)
                    byline: '', // Hide author name (empty string instead of false)
                    background: false, // Disable background mode to prevent muting
                    pip: false, // Disable picture-in-picture
                    keyboard: false, // Disable keyboard controls
                    transparent: false, // Disable transparent background
                    autopause: false, // Disable auto-pause
                    texttrack: false, // Disable text tracks
                    quality: 'auto', // Set quality to auto
                    muted: false, // Ensure video is not muted
                    volume: 1 // Set volume to maximum
                }
            };
        }

        if (isFacebook) {
            config.facebook = {
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                controls: false, // Disable native controls
                autoplay: true // Enable autoplay
            };
        }

        return config;
    };

    render() {
        const { content, onExamStart, t } = this.props;
        const { isVideoFinished, isPlaying, showExamButton, played, isSeekingLocked } = this.state;

        if (!content || !content.url) {
            return <div>{t('video_not_available')}</div>;
        }

        return (
            <Container fluid className="VideoContent h-100 d-flex flex-column p-0">
                <Row className="flex-grow-1 mb-0 bg-transparent">
                    <Col className="d-flex align-items-center justify-content-center">
                        <div style={{ width: '100%', maxWidth: '1200px', aspectRatio: '16/9' }}>
                            <ReactPlayer
                                ref={this.playerRef}
                                url={content.url}
                                playing={isPlaying}
                                controls={false}
                                width="100%"
                                height="100%"
                                onProgress={this.handleProgress}
                                onDuration={this.handleDuration}
                                config={this.getPlayerConfig(content.url)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="bg-light py-2 mt-auto">
                    <Col className="d-flex justify-content-center align-items-center">
                        {SHOW_SEEK_CONTROLS && (
                            <>
                                <Button 
                                    variant="outline-danger" 
                                    onClick={() => this.handleSkipBackward(30)}
                                    className="me-2"
                                    disabled={isSeekingLocked}
                                >
                                    <BsSkipBackward /> 30s
                                </Button>
                                <Button 
                                    variant="outline-danger" 
                                    onClick={() => this.handleSkipBackward(10)}
                                    className="me-2"
                                    disabled={isSeekingLocked}
                                >
                                    <BsSkipBackward /> 10s
                                </Button>
                            </>
                        )}
                        <Button 
                            variant="danger" 
                            onClick={this.handlePlayPause}
                            className="me-2"
                        >
                            {isPlaying ? <BsPauseFill size={24} /> : <BsPlayFill size={24} />}
                        </Button>
                        {SHOW_SEEK_CONTROLS && (
                            <>
                                <Button 
                                    variant="outline-danger" 
                                    onClick={() => this.handleSkipForward(10)}
                                    className="me-2"
                                    disabled={isSeekingLocked}
                                >
                                    10s <BsSkipForward />
                                </Button>
                                <Button 
                                    variant="outline-danger" 
                                    onClick={() => this.handleSkipForward(30)}
                                    className="me-2"
                                    disabled={isSeekingLocked}
                                >
                                    30s <BsSkipForward />
                                </Button>
                            </>
                        )}
                        {!showExamButton && (
                            <Badge bg="secondary" className="ms-4">
                                {t('video_progress', { percent: Math.round(played * 100) })}
                            </Badge>
                        )}
                        {showExamButton && (
                            <Button 
                                variant="danger" 
                                onClick={onExamStart}
                                className="ms-4"
                            >
                                {t('start_exam')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(VideoContent);
