import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import BaseCourseContent from './BaseCourseContent';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * Component for handling PDF content in courses
 */
class PDFCourseContent extends BaseCourseContent {
    static canHandle(url) {
        if (!url) return false;
        return url.toLowerCase().endsWith('.pdf');
    }

    state = {
        currentPage: 1,
        numPages: null,
        containerDimensions: { width: 0, height: 0 }
    };

    containerRef = React.createRef();

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages, currentPage: 1 });
    };

    onDocumentLoadError = (error) => {
        console.error("Error loading PDF document:", error);
    };

    handlePreviousPage = () => {
        this.setState(prevState => ({
            currentPage: Math.max(prevState.currentPage - 1, 1)
        }));
    };

    handleNextPage = () => {
        this.setState(prevState => ({
            currentPage: Math.min(prevState.currentPage + 1, prevState.numPages)
        }));
    };

    updateContainerDimensions = () => {
        if (this.containerRef.current) {
            const windowHeight = window.innerHeight;
            const navigationHeight = document.querySelector('.fixed-bottom')?.clientHeight || 0;
            const padding = 45;
            this.setState({
                containerDimensions: {
                    width: this.containerRef.current.clientWidth,
                    height: windowHeight - navigationHeight - padding,
                }
            });
        }
    };

    componentDidMount() {
        this.updateContainerDimensions();
        window.addEventListener('resize', this.updateContainerDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateContainerDimensions);
    }

    render() {
        const { content, onExamStart, t } = this.props;
        const { currentPage, numPages, containerDimensions } = this.state;

        if (!content || !content.url) {
            return <div>{t('pdf_not_available')}</div>;
        }

        return (
            <Container fluid className="PDFCourseContent" style={{ paddingBottom: '45px' }}>
                <Row>
                    <Col md={12} ref={this.containerRef} className="d-flex justify-content-center">
                        <div style={{ height: `${containerDimensions.height}px`, width: `${containerDimensions.width}px`, overflow: 'hidden' }}>
                            <Document
                                file={content.url}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onLoadError={this.onDocumentLoadError}
                                loading={<div>{t('loading')}</div>}
                                error={<div>{t('error_loading_pdf')}</div>}
                            >
                                <Page
                                    pageNumber={currentPage}
                                    width={containerDimensions.width}
                                />
                            </Document>
                        </div>
                    </Col>
                </Row>
                <Row className="fixed-bottom bg-light">
                    <Col md={3} className="d-flex align-items-center">
                        <Button variant="danger" onClick={this.handlePreviousPage} disabled={currentPage <= 1}>
                            {t('previous_slide')}
                        </Button>
                    </Col>
                    <Col md={6} className="text-center d-flex align-items-center justify-content-center">
                        {numPages && (
                            <span>
                                {t('page')} {currentPage} {t('of')} {numPages}
                            </span>
                        )}
                    </Col>
                    <Col md={3} className="d-flex align-items-center justify-content-end">
                        {currentPage < numPages && (
                            <Button variant="danger" onClick={this.handleNextPage}>{t('next_slide')}</Button>
                        )}
                        {currentPage === numPages && (
                            <Button variant="danger" onClick={onExamStart}>
                                {t('start_exam')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(PDFCourseContent);
