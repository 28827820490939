import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import i18next from 'i18next';
import Training from './Training';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import './i18n';
import LoadingSpinner from './LoadingSpinner';

const App = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [trainingId, setTrainingId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loginByName, setLoginByName] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languageOptions, setLanguageOptions] = useState([]);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const companyListUrl = `${apiUrl}companies`;

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${companyListUrl}`)
      .then(response => {
        setCompanies(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(t('fetch_error'), error);
        setIsLoading(false);
      });
  }, [companyListUrl, t]);

  useEffect(() => {
    const languages = Object.keys(i18next.options.resources);
    const options = languages.map(lang => ({
      value: lang,
      label: t(`language_${lang}`)
    }));
    setLanguageOptions(options);
    const currentLang = i18next.language;
    setSelectedLanguage(options.find(option => option.value === currentLang) || options[0]);
  }, [t]);

  useEffect(() => {
    if (company) {
      const selectedCompany = companies.find(c => c.id === company);
      if (selectedCompany && selectedCompany.users) {
        setUsers(selectedCompany.users);
      } else {
        setUsers([]);
      }
    }
  }, [company, companies, companyListUrl, t]);

  const createFakeEmailForUser = (firstName, lastName, company) => {
    const companyInfo = companies.find(c => c.id === company);
    if (!companyInfo) {
      alert(t('contact_support'));
      return null;
    }
  
    const sanitizeString = (str) => {
      const accents = {
          'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
          'Ą': 'a', 'Ć': 'c', 'Ę': 'e', 'Ł': 'l', 'Ń': 'n', 'Ó': 'o', 'Ś': 's', 'Ź': 'z', 'Ż': 'z'
      };
      return str
          .toLowerCase()
          .replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, char => accents[char])
          .replace(/[^a-z0-9-]/g, '-')
          .replace(/-+/g, '-')
          .replace(/^-+|-+$/g, '');
    };
  
    const sanitizedFirstName = sanitizeString(firstName);
    const sanitizedLastName = sanitizeString(lastName);
    const sanitizedCompanyName = sanitizeString(companyInfo.name);
  
    const emailDomain = companyInfo.email_domain ?? `${sanitizedCompanyName}.com`;
    const email = `${sanitizedFirstName}.${sanitizedLastName}@${emailDomain}`;
  
    return email.toLowerCase();
  };

  const handleUserSelect = (user) => {
    setEmail(user.username);
    setLoginByName(false);
  };

  const handleClose = () => setShowModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let dataToSend = { company, lang: selectedLanguage.value };

    if (loginByName) {
      if (!firstName || !lastName || !company) {
        alert(t('fill_all_fields'));
        return;
      }
      const fakeEmail = createFakeEmailForUser(firstName, lastName, company);
      if (!fakeEmail) return;
      dataToSend.email = fakeEmail;
    } else {
      if (!email || !company) {
        alert(t('fill_all_fields'));
        return;
      }
      dataToSend.email = email;
    }

    setIsLoading(true);
    axios.post(`${apiUrl}getForUser`, dataToSend)
      .then(response => {
        const { message, requiredTraining, uuid } = response.data;
        setMessage(message);
        setTrainingId(requiredTraining);
        setUserId(uuid);
        setShowModal(false);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(t('error_occurred'), error);
        setIsLoading(false);
      });
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18next.changeLanguage(selectedOption.value);
  };

  return (
    <div className="App container bg-transparent">
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('enter_your_data')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingSpinner isLoading={isLoading} />
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formLanguage" className="mb-3">
              <Form.Label>{t('select_language')}</Form.Label>
              <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                options={languageOptions}
              />
            </Form.Group>
            <Form.Group controlId="formCompany" className="mb-3">
              <Form.Label>{t('company')}</Form.Label>
              <Form.Control
                as="select"
                value={company}
                onChange={e => setCompany(e.target.value)}
              >
                <option value="">{t('select_company')}</option>
                {companies.map(company => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {loginByName ? (
              <>
                {company && (
                  <Form.Group controlId="formUserList" className="mb-3">
                    <Form.Label>{t('select_user')}</Form.Label>
                    <ListGroup style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {users.map(user => (
                        <ListGroup.Item
                          action
                          href="#link{user.uuid}"
                          key={user.uuid}
                          onClick={() => handleUserSelect(user)}
                        >
                          {user.name} ({user.username})
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Form.Group>
                )}
                <Form.Group controlId="formFirstName">
                  <Form.Label>{t('full_name')}</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={t('enter_first_name')}
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={t('enter_last_name')}
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              <Form.Group controlId="formEmail">
                <Form.Label>{t('login')}</Form.Label>
                <Row>
                  <Col xs={8}>
                    <Form.Control
                      type="email"
                      placeholder={t('enter_login')}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button variant="secondary" onClick={() => setLoginByName(!loginByName)}>
                      {t('no_login')}
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            )}
                  <div className="d-grid mb-3" style={{ marginTop: '35px' }}>
                    <Button variant="danger" type="submit" disabled={isLoading}>
                      {t('login')}
                    </Button>
                  </div>
            {loginByName && (
              <div className="d-grid">
                <Button variant="link" onClick={() => setLoginByName(false)}>
                  {t('back_to_login')}
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {!showModal && (
        trainingId ? (
          <Training userId={userId} trainingId={trainingId} />
        ) : (
          <div className="mt-5">
            <Modal show={true}>
              <Modal.Header>
                <Modal.Title>{t('information')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{message}</Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => window.location.reload()}>{t('ok')}</Button>
              </Modal.Footer>
            </Modal>
          </div>
        )
      )}
    </div>
  );
};

export default App;
