import React from 'react';
import PropTypes from 'prop-types';

/**
 * Base class for course content handlers
 * Each content type (PDF, YouTube, etc.) should extend this class
 * and implement required methods
 */
class BaseCourseContent extends React.Component {
    /**
     * Checks if this handler can handle given content
     * @param {string} url - Content URL to check
     * @returns {boolean} - True if this handler can handle the content
     */
    static canHandle(url) {
        throw new Error('canHandle must be implemented by child class');
    }

    /**
     * Renders the content
     * Each implementation should handle its specific content type
     */
    render() {
        return null;
    }
}

BaseCourseContent.propTypes = {
    content: PropTypes.shape({
        url: PropTypes.string.isRequired
    }).isRequired,
    onExamStart: PropTypes.func.isRequired
};

export default BaseCourseContent;
